import React from "react";
import { PageRoutage } from "../router/PageRoutage";
import { PageViewProps } from "../Page";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import logo from "../../_assets/schmidtLogo.svg";
import { Input, Button, message } from "antd";

import './viewResetPasswordAsk.css';
import { MainService } from "../../services/MainService";
import { ResetPasswordActionsNames, ActionAskResetPass } from "../../redux/reducers/ReducerResetPassword";
import { SendOutlined } from '@ant-design/icons';
import Modal from "antd/lib/modal/Modal";
import { Link } from "react-router-dom";
import { LoginRoute } from "../login/LoginView";

export const ResetPasswordAskRoute = new class extends PageRoutage<undefined> {

    getPath() {
        let path = `/passwordReset`;

        // if(p) {
        //     path = path
        //         .replace(':idProject', p.idProject + '')
        //         .replace(':version', p.version + '');
        // }

        return path;
    }
    
};

// type RouteHeaderParams = "code";



export interface RPassAskProps extends PageViewProps<'RESET_PASSWORD_ASK'> {
    // resetSent: boolean;
}


export interface RPassAskState {
    
    email: string;
    // alreadySaid: boolean;

}

interface RPassAskDispatchProps {
	sendResetPasswordAsk: (email: string) => void;
}

type FullRPassAskProps = RPassAskProps & RPassAskDispatchProps;


export class ViewResetPasswordAsk extends React.PureComponent<FullRPassAskProps, RPassAskState> {

    constructor(props: FullRPassAskProps) {
        super(props);
        
        this.state = {
            email: ""
        }
	}

	render() {

		return <div className="viewResetPasswordAsk">
            <div id="backViewResetPass">
                <div id="head">
                    <Link to={LoginRoute.getPath()}><img height="50px" src={logo} /> </Link>
                </div>
                <div id="body">
                    <h2 id="title">{process.env.REACT_APP_PASSWORD_INIT}</h2>
                    <div id="rest">
                        <div id="description" dangerouslySetInnerHTML={{__html: process.env.REACT_APP_DESC as string}}></div>
                        <Input title={process.env.REACT_APP_EMAIL} type='email' className="inputEmail" placeholder={process.env.REACT_APP_EMAIL} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
                        <Button type="primary" 
                                shape="round" 
                                disabled={this.state.email === "" || !MainService.validateEmailAddress(this.state.email)} 
                                className="btn" 
                                onClick={() => {this.props.sendResetPasswordAsk(this.state.email);}}
                                icon={<SendOutlined />}>
                                    {process.env.REACT_APP_ENVOYER}
                        </Button>
                    </div>
                </div>
            </div>
		</div>
    }
    
    
} 

const mapDispatchToProps = (dispatch: Dispatch, props: RPassAskProps): RPassAskDispatchProps => {
    return {
        sendResetPasswordAsk: (email: string) => {
            dispatch({
                type: ResetPasswordActionsNames.ASK_PASSWORD_RESET,
                email,
                message: () => message.success(process.env.REACT_APP_RESET_SUCCESS)
            } as ActionAskResetPass)
        }
    }
}

const mapStateToProps = (state: any): RPassAskProps => {
    return {
        ...state.pageProps as RPassAskProps
    };
}

export default connect<{}, RPassAskDispatchProps, RPassAskProps>(mapStateToProps, mapDispatchToProps)(ViewResetPasswordAsk);