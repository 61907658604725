import * as React from "react";
import {PageRoutage} from "../router/PageRoutage";
import {Dispatch} from "redux";
import {ErrorService, MainErrorProps, MainErrorState} from "../../services/ErrorService";
import './loginView.css';
import { toast } from 'react-toastify';
import { ActionReloadLogin, LoginActions, ActionCheckLogin } from '../../redux/reducers/ReducerLogin';
import { connect } from 'react-redux';
import { PageViewProps } from '../Page';
import { Redirect, Link } from 'react-router-dom';
import { UserMetadata } from "../../controllers/ControllerUser";
import logo from "../../_assets/schmidtLogo.svg";
import { Input, Button } from 'antd';
import { UserOutlined, KeyOutlined, LoginOutlined } from '@ant-design/icons';
import { ResetPasswordAskRoute } from "../resetPasswordAsk/ViewResetPasswordAsk";

export const LoginRoute = new class extends PageRoutage<undefined> {

    getPath() {
        let path = `/login`;

        // if(p) {
        //     path = path
        //         .replace(':idProject', p.idProject + '')
        //         .replace(':version', p.version + '');
        // }

        return path;
    }
    
};



// type RouteHeaderParams = "code";



export interface LoginProps extends MainErrorProps, PageViewProps<'LOGIN'> {
    // dispatcher: Dispatch<StoreAction>;
    userProperties?: UserMetadata;
    creationOk?: boolean;
    errorCredentials: boolean;
    redirect?: string;
}


export interface LoginState extends MainErrorState{
    isWaitingForServer: boolean;
    errorPasswordNotMatching: boolean;
    username: string;
    password: string;
    isAdmin: boolean;
}

interface LoginDispatchProps {
    loginReload: (noMoreErrors?: boolean, noMorePasswordWrong?: boolean, noMoreEmailNotVerified?: boolean) => void;
    connect: (user: string, mdp: string) => void;
}

type FullLoginProps = LoginProps & LoginDispatchProps;


export class LoginView extends React.PureComponent<FullLoginProps, LoginState> {

    constructor(props: FullLoginProps) {
        super(props);

        if (!this.props.userProperties) {
            //TODO
        }

        this.state = {
            ...ErrorService.getErrorState(props),
            isWaitingForServer: false,
            errorPasswordNotMatching: false,
            username: "",
            password: "",
            isAdmin: false
        }
    }

    render(): React.ReactNode {
        // console.log("------------------ LoginView ------------------", this.props, this.state);
        // const loginMessageDiv = ErrorService.getErrorDiv(this.state);

        if (this.props.redirect) {
            return <Redirect to={this.props.redirect} />
        }

        if (this.props.errorCredentials) {
            const message = <div className="messageToast"><span role="img" aria-label="Bad credentials">❌</span> <p>{process.env.REACT_APP_BAD_CREDS}</p></div>;
            toast.error(message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                toastId: 1,
                onClose: ()=>this.props.loginReload(true)
            });
        }

        return <div className={'page page-login'}>

            <div id='backLogin'>

                <div id="head">
                    <img src={logo} />
                    <span>{process.env.REACT_APP_TITLE}</span>
                </div>

                <div id="body">
                    <span className="connectez">{process.env.REACT_APP_CONNEXION}</span>
                    <div id="line"/>
                    <div id="login">
                        <Input size="large" className="inputLogin" placeholder={process.env.REACT_APP_LOGIN} prefix={<UserOutlined />} value={this.state.username} onChange={(event) => this.setState({username: event.target.value})}/>
                        <Input size="large" type='password' className="inputLogin" placeholder={process.env.REACT_APP_PASSWORD} prefix={<KeyOutlined />} value={this.state.password} onChange={(event) => this.setState({password: event.target.value})} />
                    </div>
                    <div id="valide">
                        <Link to={ResetPasswordAskRoute.getPath()}>{process.env.REACT_APP_FORGOT_PASSWORD}</Link>
                        <Button shape="round" icon={<LoginOutlined />} className="btnValide" htmlType="submit" disabled={this.state.username === "" || this.state.password===""} onClick={() => this.props.connect(this.state.username, this.state.password)}>{process.env.REACT_APP_CONNECT}</Button>
                    </div>
                </div>

                
            </div>
            

        </div>;
    }

    componentDidUpdate(prevProps: LoginProps, prevState: LoginState): void {
        if(ErrorService.isAnyChangeForComponentDidUpdate(this.props, prevProps, this.state, prevState)){
            const errorState = ErrorService.getErrorState(this.props);
            this.setState(errorState);
        }
    }


}

const mapDispatchToProps = (dispatch: Dispatch, props: LoginProps): LoginDispatchProps => {
    return {
        loginReload: (noErrors?:boolean, nmpw?: boolean, nmenv?: boolean) => dispatch({ type: LoginActions.LOGIN_RELOAD,noMoreErrors: noErrors, noMorePasswordWrong: nmpw, noMoreEmailNotVerified: nmenv} as ActionReloadLogin),
        connect: (user: string, mdp: string) => dispatch({type: LoginActions.LOGIN_CHECK_USER_AND_PASSWORD, email: user, password: mdp} as ActionCheckLogin) 
    }
}

const mapStateToProps = (state: any): LoginProps => {
    return {
        ...state.pageProps as LoginProps
    };
}

export default connect<{}, LoginDispatchProps, LoginProps>(mapStateToProps, mapDispatchToProps)(LoginView);