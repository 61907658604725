import { EventName, SocketLike } from "./ServerInterfaces";
import ControllerMain from "../controllers/ControllerMain";
import { WaitingActionsNames } from "../redux/reducers/ReducerWaiting";
const iot = require("socket.io-client");

export interface ServerCoreDEBUG {
    readonly URL_SERVER: string;
    readonly iot: SocketIOClientStatic & any;
}

export default class ServerCore implements SocketLike<SocketIOClient.Emitter, SocketIOClient.Socket> {

    static readonly ERRORS = {
        URL_SERVER: "env URL_SERVER non défini",
        IO: "io déjà défini",
        CONNECT_FAILED: "io connect failed"
    };

    private socket!: SocketIOClient.Socket;

    private connected: boolean;

    private controllerMain: ControllerMain;

    constructor(ctm: ControllerMain) {
        this.connected = false;
        this.controllerMain = ctm;
    }

    init(debug?: ServerCoreDEBUG) {
        return new Promise((resolve, reject) => {

            let URL_SERVER: string | undefined;
            let sio: SocketIOClientStatic;
            if (debug) {
                URL_SERVER = debug.URL_SERVER;
                sio = debug.iot;
            } else {
                URL_SERVER = process.env.REACT_APP_URL_SERVER;
                sio = iot;
            }

            if (!URL_SERVER) {
                throw new Error(ServerCore.ERRORS.URL_SERVER);
            }

            if (this.socket) {
                throw new Error(ServerCore.ERRORS.IO);
            }

            this.socket = sio(URL_SERVER || "")//, {transports: ["polling"]});

            const onThisDoThat: {
                on: EventName[];
                fct: (data?: any) => void;
            }[] = [
                {
                    on: ["connect_timeout", "connect_error"],
                    fct: () => {
                        reject(ServerCore.ERRORS.CONNECT_FAILED);
                    }
                },
                {
                    on: ["connect"],
                    fct: () => {
                        this.connected = true;
                        // console.log('CONNECTED UI')
                        resolve({});
                    }
                },
                {
                    on: ["reconnect"],
                    fct: () => {
                        this.connected = true;
                    }
                },
                {
                    on: ["disconnect"],
                    fct: () => {
                        this.connected = false;
                    }
                },
                {
                    on: ["data/get_single"],
                    fct: (d) => {
                        this.controllerMain.dispatch({
                            type: WaitingActionsNames.RECEIVING_FILES,
                            ...d
                        })
                    }
                },
                {
                    on: ["connect_error"], 
                    fct: (err) => {
                        console.log(`connect_error due to ${err.message}`);
                    }
                }
            ];

            for (const act of onThisDoThat) {
                for (const on of act.on) {
                    this.on(on, act.fct);
                }
            }

        });
    }

    isConnected(): boolean {
        return this.connected;
    }

    on(eventName: EventName, fct: Function): SocketIOClient.Emitter {
        return this.socket.on(eventName, fct);
    }

    emit(eventName: EventName, ...args: any[]): SocketIOClient.Socket {
        return this.socket.emit(eventName, args);
    }

}
